import { graphql, Link } from 'gatsby' 
import React, { useState } from 'react'

import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'

import Helmet from "react-helmet"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Collapse from "react-bootstrap/Collapse";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";

interface Props {
  location: Location
}

const states = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District of Columbia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
  { value: 'AB', label: 'Alberta' },
  { value: 'BC', label: 'British Columbia' },
  { value: 'MB', label: 'Manitoba' },
  { value: 'NB', label: 'New Brunswick' },
  { value: 'NL', label: 'Newfoundland and Labrador' },
  { value: 'NS', label: 'Nova Scotia' },
  { value: 'NT', label: 'Northwest Territories' },
  { value: 'NU', label: 'Nunavut' },
  { value: 'ON', label: 'Ontario' },
  { value: 'PE', label: 'Prince Edward Island' },
  { value: 'QC', label: 'Quebec' },
  { value: 'SK', label: 'Saskatchewan' },
  { value: 'YT', label: 'Yukon' },
];


const countries = [
  {value: "AF",label: "Afghanistan"},
  {value: "AX",label: "Aland Islands"},
  {value: "AL",label: "Albania"},
  {value: "DZ",label: "Algeria"},
  {value: "AS",label: "American Samoa"},
  {value: "AD",label: "Andorra"},
  {value: "AO",label: "Angola"},
  {value: "AI",label: "Anguilla"},
  {value: "AQ",label: "Antarctica"},
  {value: "AG",label: "Antigua and Barbuda"},
  {value: "AR",label: "Argentina"},
  {value: "AM",label: "Armenia"},
  {value: "AW",label: "Aruba"},
  {value: "AU",label: "Australia"},
  {value: "AT",label: "Austria"},
  {value: "AZ",label: "Azerbaijan"},
  {value: "BS",label: "Bahamas"},
  {value: "BH",label: "Bahrain"},
  {value: "BD",label: "Bangladesh"},
  {value: "BB",label: "Barbados"},
  {value: "BY",label: "Belarus"},
  {value: "BE",label: "Belgium"},
  {value: "BZ",label: "Belize"},
  {value: "BJ",label: "Benin"},
  {value: "BM",label: "Bermuda"},
  {value: "BT",label: "Bhutan"},
  {value: "BO",label: "Bolivia"},
  {value: "BQ",label: "Bonaire, Sint Eustatius and Saba"},
  {value: "BA",label: "Bosnia and Herzegovina"},
  {value: "BW",label: "Botswana"},
  {value: "BV",label: "Bouvet Island"},
  {value: "BR",label: "Brazil"},
  {value: "IO",label: "British Indian Ocean Territory"},
  {value: "BN",label: "Brunei Darussalam"},
  {value: "BG",label: "Bulgaria"},
  {value: "BF",label: "Burkina Faso"},
  {value: "BI",label: "Burundi"},
  {value: "KH",label: "Cambodia"},
  {value: "CM",label: "Cameroon"},
  {value: "CA",label: "Canada"},
  {value: "CV",label: "Cape Verde"},
  {value: "KY",label: "Cayman Islands"},
  {value: "CF",label: "Central African Republic"},
  {value: "TD",label: "Chad"},
  {value: "CL",label: "Chile"},
  {value: "CN",label: "China"},
  {value: "CX",label: "Christmas Island"},
  {value: "CC",label: "Cocos (Keeling) Islands"},
  {value: "CO",label: "Colombia"},
  {value: "KM",label: "Comoros"},
  {value: "CG",label: "Congo"},
  {value: "CD",label: "Congo, Democratic Republic of the Congo"},
  {value: "CK",label: "Cook Islands"},
  {value: "CR",label: "Costa Rica"},
  {value: "CI",label: "Cote D'Ivoire"},
  {value: "HR",label: "Croatia"},
  {value: "CU",label: "Cuba"},
  {value: "CW",label: "Curacao"},
  {value: "CY",label: "Cyprus"},
  {value: "CZ",label: "Czech Republic"},
  {value: "DK",label: "Denmark"},
  {value: "DJ",label: "Djibouti"},
  {value: "DM",label: "Dominica"},
  {value: "DO",label: "Dominican Republic"},
  {value: "EC",label: "Ecuador"},
  {value: "EG",label: "Egypt"},
  {value: "SV",label: "El Salvador"},
  {value: "GQ",label: "Equatorial Guinea"},
  {value: "ER",label: "Eritrea"},
  {value: "EE",label: "Estonia"},
  {value: "ET",label: "Ethiopia"},
  {value: "FK",label: "Falkland Islands (Malvinas)"},
  {value: "FO",label: "Faroe Islands"},
  {value: "FJ",label: "Fiji"},
  {value: "FI",label: "Finland"},
  {value: "FR",label: "France"},
  {value: "GF",label: "French Guiana"},
  {value: "PF",label: "French Polynesia"},
  {value: "TF",label: "French Southern Territories"},
  {value: "GA",label: "Gabon"},
  {value: "GM",label: "Gambia"},
  {value: "GE",label: "Georgia"},
  {value: "DE",label: "Germany"},
  {value: "GH",label: "Ghana"},
  {value: "GI",label: "Gibraltar"},
  {value: "GR",label: "Greece"},
  {value: "GL",label: "Greenland"},
  {value: "GD",label: "Grenada"},
  {value: "GP",label: "Guadeloupe"},
  {value: "GU",label: "Guam"},
  {value: "GT",label: "Guatemala"},
  {value: "GG",label: "Guernsey"},
  {value: "GN",label: "Guinea"},
  {value: "GW",label: "Guinea-Bissau"},
  {value: "GY",label: "Guyana"},
  {value: "HT",label: "Haiti"},
  {value: "HM",label: "Heard Island and McDonald Islands"},
  {value: "VA",label: "Holy See (Vatican City State)"},
  {value: "HN",label: "Honduras"},
  {value: "HK",label: "Hong Kong"},
  {value: "HU",label: "Hungary"},
  {value: "IS",label: "Iceland"},
  {value: "IN",label: "India"},
  {value: "ID",label: "Indonesia"},
  {value: "IR",label: "Iran, Islamic Republic of"},
  {value: "IQ",label: "Iraq"},
  {value: "IE",label: "Ireland"},
  {value: "IM",label: "Isle of Man"},
  {value: "IL",label: "Israel"},
  {value: "IT",label: "Italy"},
  {value: "JM",label: "Jamaica"},
  {value: "JP",label: "Japan"},
  {value: "JE",label: "Jersey"},
  {value: "JO",label: "Jordan"},
  {value: "KZ",label: "Kazakhstan"},
  {value: "KE",label: "Kenya"},
  {value: "KI",label: "Kiribati"},
  {value: "KP",label: "Korea, Democratic People's Republic of"},
  {value: "KR",label: "Korea, Republic of"},
  {value: "XK",label: "Kosovo"},
  {value: "KW",label: "Kuwait"},
  {value: "KG",label: "Kyrgyzstan"},
  {value: "LA",label: "Lao People's Democratic Republic"},
  {value: "LV",label: "Latvia"},
  {value: "LB",label: "Lebanon"},
  {value: "LS",label: "Lesotho"},
  {value: "LR",label: "Liberia"},
  {value: "LY",label: "Libyan Arab Jamahiriya"},
  {value: "LI",label: "Liechtenstein"},
  {value: "LT",label: "Lithuania"},
  {value: "LU",label: "Luxembourg"},
  {value: "MO",label: "Macao"},
  {value: "MK",label: "Macedonia, the Former Yugoslav Republic of"},
  {value: "MG",label: "Madagascar"},
  {value: "MW",label: "Malawi"},
  {value: "MY",label: "Malaysia"},
  {value: "MV",label: "Maldives"},
  {value: "ML",label: "Mali"},
  {value: "MT",label: "Malta"},
  {value: "MH",label: "Marshall Islands"},
  {value: "MQ",label: "Martinique"},
  {value: "MR",label: "Mauritania"},
  {value: "MU",label: "Mauritius"},
  {value: "YT",label: "Mayotte"},
  {value: "MX",label: "Mexico"},
  {value: "FM",label: "Micronesia, Federated States of"},
  {value: "MD",label: "Moldova, Republic of"},
  {value: "MC",label: "Monaco"},
  {value: "MN",label: "Mongolia"},
  {value: "ME",label: "Montenegro"},
  {value: "MS",label: "Montserrat"},
  {value: "MA",label: "Morocco"},
  {value: "MZ",label: "Mozambique"},
  {value: "MM",label: "Myanmar"},
  {value: "NA",label: "Namibia"},
  {value: "NR",label: "Nauru"},
  {value: "NP",label: "Nepal"},
  {value: "NL",label: "Netherlands"},
  {value: "AN",label: "Netherlands Antilles"},
  {value: "NC",label: "New Caledonia"},
  {value: "NZ",label: "New Zealand"},
  {value: "NI",label: "Nicaragua"},
  {value: "NE",label: "Niger"},
  {value: "NG",label: "Nigeria"},
  {value: "NU",label: "Niue"},
  {value: "NF",label: "Norfolk Island"},
  {value: "MP",label: "Northern Mariana Islands"},
  {value: "NO",label: "Norway"},
  {value: "OM",label: "Oman"},
  {value: "PK",label: "Pakistan"},
  {value: "PW",label: "Palau"},
  {value: "PS",label: "Palestinian Territory, Occupied"},
  {value: "PA",label: "Panama"},
  {value: "PG",label: "Papua New Guinea"},
  {value: "PY",label: "Paraguay"},
  {value: "PE",label: "Peru"},
  {value: "PH",label: "Philippines"},
  {value: "PN",label: "Pitcairn"},
  {value: "PL",label: "Poland"},
  {value: "PT",label: "Portugal"},
  {value: "PR",label: "Puerto Rico"},
  {value: "QA",label: "Qatar"},
  {value: "RE",label: "Reunion"},
  {value: "RO",label: "Romania"},
  {value: "RU",label: "Russian Federation"},
  {value: "RW",label: "Rwanda"},
  {value: "BL",label: "Saint Barthelemy"},
  {value: "SH",label: "Saint Helena"},
  {value: "KN",label: "Saint Kitts and Nevis"},
  {value: "LC",label: "Saint Lucia"},
  {value: "MF",label: "Saint Martin"},
  {value: "PM",label: "Saint Pierre and Miquelon"},
  {value: "VC",label: "Saint Vincent and the Grenadines"},
  {value: "WS",label: "Samoa"},
  {value: "SM",label: "San Marino"},
  {value: "ST",label: "Sao Tome and Principe"},
  {value: "SA",label: "Saudi Arabia"},
  {value: "SN",label: "Senegal"},
  {value: "RS",label: "Serbia"},
  {value: "CS",label: "Serbia and Montenegro"},
  {value: "SC",label: "Seychelles"},
  {value: "SL",label: "Sierra Leone"},
  {value: "SG",label: "Singapore"},
  {value: "SX",label: "St Martin"},
  {value: "SK",label: "Slovakia"},
  {value: "SI",label: "Slovenia"},
  {value: "SB",label: "Solomon Islands"},
  {value: "SO",label: "Somalia"},
  {value: "ZA",label: "South Africa"},
  {value: "GS",label: "South Georgia and the South Sandwich Islands"},
  {value: "SS",label: "South Sudan"},
  {value: "ES",label: "Spain"},
  {value: "LK",label: "Sri Lanka"},
  {value: "SD",label: "Sudan"},
  {value: "SR",label: "Suriname"},
  {value: "SJ",label: "Svalbard and Jan Mayen"},
  {value: "SZ",label: "Swaziland"},
  {value: "SE",label: "Sweden"},
  {value: "CH",label: "Switzerland"},
  {value: "SY",label: "Syrian Arab Republic"},
  {value: "TW",label: "Taiwan, Province of China"},
  {value: "TJ",label: "Tajikistan"},
  {value: "TZ",label: "Tanzania, United Republic of"},
  {value: "TH",label: "Thailand"},
  {value: "TL",label: "Timor-Leste"},
  {value: "TG",label: "Togo"},
  {value: "TK",label: "Tokelau"},
  {value: "TO",label: "Tonga"},
  {value: "TT",label: "Trinidad and Tobago"},
  {value: "TN",label: "Tunisia"},
  {value: "TR",label: "Turkey"},
  {value: "TM",label: "Turkmenistan"},
  {value: "TC",label: "Turks and Caicos Islands"},
  {value: "TV",label: "Tuvalu"},
  {value: "UG",label: "Uganda"},
  {value: "UA",label: "Ukraine"},
  {value: "AE",label: "United Arab Emirates"},
  {value: "GB",label: "United Kingdom"},
  {value: "US",label: "United States"},
  {value: "UM",label: "United States Minor Outlying Islands"},
  {value: "UY",label: "Uruguay"},
  {value: "UZ",label: "Uzbekistan"},
  {value: "VU",label: "Vanuatu"},
  {value: "VE",label: "Venezuela"},
  {value: "VN",label: "Viet Nam"},
  {value: "VG",label: "Virgin Islands, British"},
  {value: "VI",label: "Virgin Islands, U.s."},
  {value: "WF",label: "Wallis and Futuna"},
  {value: "EH",label: "Western Sahara"},
  {value: "YE",label: "Yemen"},
  {value: "ZM",label: "Zambia"},
  {value: "ZW",label: "Zimbabwe"}
];

const Contact: React.FC<Props> = ({ location }: Props) => {
  const [validated, setValidated] = React.useState(false)

  const handleCaptcha = e => {
    window.grecaptcha.ready(function() {
      window.grecaptcha
      .execute("6LfTzKwbAAAAADIlZuHGHnafR7avLdOYju45qFXU", { action: "submit" }) 
      .then(function(token) {
        var recaptchaResponse = document.getElementById("g-recaptcha")
        recaptchaResponse.value = token
      })
    })
  }


  const handleSubmit = event => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault()
      event.stopPropagation()
    }

    setValidated(true)
  }
  
  const [refopen, setRefOpen] = useState(false);
  const [otheropen, setOtherOpen] = useState(false);

  

  const [selectedState, setSelectedState] = useState('');

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
  };

  const [selectedCountry, setSelectedCountry] = useState('US');



  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };
  
  
  return (
    <Layout location={location}>
      <Meta site={Meta} title="Contact Us" />
      <Helmet
        bodyAttributes={{
          class: "page-contact-us",
        }}
      />
      <div className="container px-6">
        <section>
          <div className="container">
            <h1>Contact Us</h1>
            <Row>
              <Col md={8}>
                <p>Complete the form below and we'll be in touch. <br /><small>(<span className="required"></span> fields are required)</small></p>
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit}
                  className="w-md-75 mx-auto"
                  method={"POST"}
                  encType={"multipart/form-data"}
                  action={"/send.php"} 
                >
                  <Form.Group controlId="formName">
                    <Form.Label className="required">First Name</Form.Label>
                    <Form.Control required type="text" name="formName" />
                  </Form.Group>

                  <Form.Group controlId="formLastName">
                    <Form.Label className="required">Last Name</Form.Label>
                    <Form.Control required type="text" name="formLastName" />
                  </Form.Group>

                  <Form.Group controlId="formEmail">
                    <Form.Label className="required">Email</Form.Label>
                    <Form.Control required type="email" name="formEmail" />
                  </Form.Group>

                  <Form.Group controlId="formPhone">
                    <Form.Label className="required">Phone</Form.Label>
                    <Form.Control required type="tel" name="formPhone" />
                  </Form.Group>
                  
                  <Form.Group controlId="formCompany">
                    <Form.Label className="required">Company</Form.Label>
                    <Form.Control required type="text" name="formCompany" />
                  </Form.Group>

                  <Form.Group controlId="formProduct">
                    <Form.Label>Product</Form.Label>
                    <Form.Control type="text" name="formProduct" />
                  </Form.Group>

                  <Form.Group controlId="formAddress">
                    <Form.Label className="required">Address</Form.Label>
                    <Form.Control required type="text" name="formAddress" />
                  </Form.Group>

                  <Form.Group controlId="formAddress2">
                    <Form.Label>Address2</Form.Label>
                    <Form.Control type="text" name="formAddress2" />
                  </Form.Group>

                  <Form.Group controlId="formCity">
                    <Form.Label className="required">City</Form.Label>
                    <Form.Control required type="text" name="formCity" />
                  </Form.Group>

                  <Form.Group controlId="formState">
                    <Form.Label className="required">State:</Form.Label>
                    <InputGroup>
                      <FormControl
                        as="select"
                        value={selectedState}
                        onChange={handleStateChange}
                        required
                        name="formState"
                      >
                        <option value="">Select State</option>
                        {states.map((state) => (
                          <option key={state.value} value={state.value}>
                            {state.label}
                          </option>
                        ))}
                      </FormControl>
                    </InputGroup>
                  </Form.Group>
                  

                  <Form.Group controlId="formZip">
                    <Form.Label className="required">Zip/Postal Code</Form.Label>
                    <Form.Control required type="text" name="formZip" />
                  </Form.Group>

                  <Form.Group controlId="formCountry">
                    <Form.Label className="required">Country:</Form.Label>
                    <InputGroup>
                      <FormControl
                        as="select"
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        required
                        name="formCountry"
                      >
                        <option value="">Select Country</option>
                        {countries.map((country) => (
                          <option key={country.value} value={country.value}>
                            {country.label}
                          </option>
                        ))}
                      </FormControl>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="">How did you hear about us?</Form.Label>
                    <Form.Check
                      type="radio"
                      label="Email"
                      id="email"
                      name="form-referredby"
                      value="Email"
                      onClick={() => { if(otheropen) { setOtherOpen(!otheropen)}; if (refopen) {setRefOpen(!refopen)};}}
                    />
                    <Form.Check
                      type="radio"
                      label="LinkedIn"
                      id="linkedin"
                      name="form-referredby"
                      value="LinkedIn"
                      onClick={() => { if(otheropen) { setOtherOpen(!otheropen)}; if (refopen) {setRefOpen(!refopen)};}}
                    />
                    <Form.Check
                      type="radio"
                      label="Facebook"
                      id="facebook"
                      name="form-referredby"
                      value="Facebook"
                      onClick={() => { if(otheropen) { setOtherOpen(!otheropen)}; if (refopen) {setRefOpen(!refopen)};}}
                    />
                    <Form.Check
                      type="radio"
                      label="Google Business"
                      id="google"
                      name="form-referredby"
                      value="Google Business"
                      onClick={() => { if(otheropen) { setOtherOpen(!otheropen)}; if (refopen) {setRefOpen(!refopen)};}}
                    />
                    <Form.Check
                      type="radio"
                      label="Web search"
                      id="websearch"
                      name="form-referredby"
                      value="Web search"
                      onClick={() => { if(otheropen) { setOtherOpen(!otheropen)}; if (refopen) {setRefOpen(!refopen)};}}
                    />
                    <Form.Check
                      type="radio"
                      label="Referral"
                      id="referral"
                      name="form-referredby"
                      value="Referral"
                      onClick={() => { setRefOpen(true); if (otheropen) {setOtherOpen(!otheropen)};}}
                      aria-controls="referral-name"
                      aria-expanded={refopen}
                    />
                    <Form.Check
                      type="radio"
                      label="Other"
                      id="other"
                      name="form-referredby"
                      value="Other"
                      onClick={() => { setOtherOpen(true); if (refopen) {setRefOpen(!refopen)};}}
                      aria-controls="other-details"
                      aria-expanded={otheropen}
                    />
                  </Form.Group>

                  <Collapse in={refopen}>
                    <Form.Group controlId="formReferrer" id="referral-name">
                      <Form.Label>Referral Name</Form.Label>
                      <div className="form-text text-muted">That's great to hear! Please let us know who referred you so that we can extend our thanks.</div>
                      <Form.Control type="text" name="formReferrer" />
                    </Form.Group>
                  </Collapse>

                  <Collapse in={otheropen}>
                    <Form.Group controlId="formReferrer" id="other-details">
                      <Form.Label>Other Details</Form.Label>
                      <div className="form-text text-muted">Great! Please tell us a bit more about how you heard about us using the field below.</div>
                      <Form.Control type="text" name="otherDetails" />
                    </Form.Group>
                  </Collapse>

                  <Form.Group controlId="formMoreInfo" onFocus={e => handleCaptcha(e)}>
                    <Form.Label className="required">What can we help you with?</Form.Label>
                    <Form.Control required name="formMoreInfo" as="textarea" rows={5} />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Do you have a contact preference?</Form.Label>
                    <Form.Check
                      type="checkbox"
                      label="Phone"
                      id="method-phone"
                      name="form-contactMethod-Phone"
                    />
                    <Form.Check
                      type="checkbox"
                      label="Email"
                      id="method-email"
                      name="form-contactMethod-Email"
                    />
                  </Form.Group> 

                  <Form.Group>
                    <Form.Label>Upload a file</Form.Label>
                    <Form.Control
                      type="file"
                      id="attachment"
                      name="attachment"
                    />
                    <small id="attachHelp" className="form-text text-muted">Uploaded files must be less than 10 MB. Allowed file types: pdf, doc, docx, png, jpg, gif. </small>
                  </Form.Group>

                  <Form.Control
                    type="hidden"
                    id="g-recaptcha"
                    name="g_recaptcha"
                    required
                  ></Form.Control>

                  <Button variant="btn btn-success" type="submit" className="mt-3">
                    Submit
                  </Button>
                </Form>
              </Col>
              <Col md={4}>
                <address>
                  <strong>Center for Building Innovation</strong> <br />
                  6300 Enterprise Lane <br />
                  Madison, WI 53719 <br /><br />
                  <i className="fa fa-phone" aria-hidden="true"></i> <a href="tel:16083106739">608-310-6739</a>
                </address>                
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Contact

export const pageQuery = graphql`
  query ContactQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
      }
    }
  }
`
